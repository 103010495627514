// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "@auth/redux/reducer";
import navbar from "./navbar";
import layout from "./layout";

// import list from './custom'
import validator from "./validator";

import form from "./form";
import select from "./selects";

import fileUpload from "./fileUpload";
import list from "./list";

import calendar from "./calendar";
import alertReducer from "./alert";
import reserved from "./reserved";
import transfer from "./transfer";
import routes from "./routes";

const rootReducer = combineReducers({
    auth,
    navbar,
    list,
    layout,
    form,
    select,
    fileUpload,
    calendar,
    validator,
    alertReducer,
    reserved,
    transfer,
    routes,
});

export default rootReducer;
