import { formValidatorTypes } from "../../types/validator";

// ** Initial State
const initialState = {
    errors: [],
};

const validator = (state = initialState, action) => {
    switch (action.type) {
        case formValidatorTypes.setErrors:
            return {
                ...state,
                errors: [...action.payload],
            };
        case formValidatorTypes.removeError:
            return {
                ...state,
                errors: [...state.errors.filter((f) => f !== action.payload)],
            };
        case formValidatorTypes.cleanFormValidator:
            return initialState;

        default:
            return state;
    }
};

export default validator;
