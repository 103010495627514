export const getDateTime = (
    date = null,
    separator = "-",
    obj = { showDate: true, showTime: false, showFullTime: false, reverse: false },
) => {
    if (date === null) return;
    const { showDate = true, showTime = false, showFullTime = false, reverse = false } = obj;
    date = date ? new Date(date) : new Date();
    const array = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    const format = separator === "-" ? array : !reverse ? array.reverse() : array;

    const fullDay = format.map((n) => (n < 10 ? `0${n}` : n)).join(separator);
    const timeArray = [date.getHours(), date.getMinutes(), date.getSeconds()];
    if (!showFullTime) timeArray.pop();
    const time = timeArray.map((n) => (n < 10 ? `0${n}` : n)).join(":");
    const returnedDateTime = `${showDate ? fullDay : ""}${showTime || showFullTime ? ` ${time}` : ""}`;
    return returnedDateTime.trim();
};
