import { transferTypes } from "../../types/transfer";

// ** Initial State
const initialState = {};

const tranferReducer = (state = initialState, action) => {
    switch (action.type) {
        case transferTypes.clean:
            return initialState;
        case transferTypes.init:
            return {
                target: action.payload.target,
                ids: action.payload.ids,
                warehouseTo: action.payload.to,
                warehouseFrom: action.payload.from,
            };
        case transferTypes.updateIds:
            return {
                ...state,
                ids: action.payload,
            };
        case transferTypes.setProduct:
            return state;
        default:
            return state;
    }
};

export default tranferReducer;
